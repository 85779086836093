import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueYoutube from 'vue-youtube'
import VueMask from 'v-mask'
import Vue2TouchEvents from 'vue2-touch-events'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { Plugin } from 'vue-responsive-video-background-player'
import 'leaflet/dist/leaflet.css'
import './vee-validate'
import TagSelector from './components/Tags.vue'
import LocationSelector from './components/Locations.vue'
import PeopleSelector from './components/People.vue'
import EmojiCodePopup from './components/EmojiCode.vue'
import VueGtag from 'vue-gtag'
import './registerServiceWorker'

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('TagSelector', TagSelector)
Vue.component('LocationSelector', LocationSelector)
Vue.component('PeopleSelector', PeopleSelector)
Vue.component('EmojiCodePopup', EmojiCodePopup)
// Vue.component('video-background', VideoBackground)

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(VueMask, {})
Vue.use(Vue2TouchEvents)
Vue.use(Plugin)
Vue.use(VueGtag, {
  config: { id: 'UA-175897131-1' }
}, router)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
